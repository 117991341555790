@import 'animate.css';


/* navbar css start */
.navbar-main-sec{
    /* position: sticky;
    top: 0px;
    z-index: 0;
     */
    background: wheat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
  .navbar-expand-lg .navbar-collapse {
    justify-content: start;
  }
  .navbar-logo img {
    height: 47px;
    margin-top: -7px;
  }
  
  .main-nvlnk ul li {
    margin: 0px 5px;
  }
  .main-nvlnk ul li a {
    color: #000000db !important;
    font-weight: 700;
    font-size: 15px;
  }
  
  .main-nvlnk ul li a:hover {
    color: #ef7f1a !important;
  }
  
  /* navbar css end */

  /* sec 1 */
  .sec-1{
    padding: 50px 0px;
    padding-top: 40px !important;
    /* background-color: #f0f8ff99; */
    margin-top: 30px;
  }
  .sec-1Col-txt{
    display: flex;
    align-items: center;
    color: #ef7f1a;
  }
  .sec-1Col-txt h1{
    font-weight: bold;
  }
  .sec-1Col-Img{
    margin-top: 30px;
    text-align: center;
  }

  /* sec 2 */
  .sec-2Row-1,
  .sec-2Row-2,
  .sec-2Row-3{
    /* margin: 30px 0px; */
    text-align: center;
    /* margin-bottom: 50px; */
  }

  .sec-2 h3{
    color: #ef7f1a;
    font-weight: 700;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .booksBg{
    padding: 7px 7px;
    margin: 0px 20px;
    border-radius: 4px;
    margin-bottom: 55px;
  }


  /* sec 3 */
  .sec-3{
    padding: 60px 0px;
  }
  .sec-3 h2{
    text-align: center;
    margin-bottom: 30px;
    color: #ef7f1a;
    
  }
  .sec-3{
    padding: 10px 65px;
  }
  .sec-3Rowhed{
    margin-bottom: 27px;
  }
  .sec-3RowMaintextContainer div{
    line-height: 35px;
  }
  .sec-3RowMaintextContainer svg{
    font-size: 25px;
  }
  .sec-3RowMaintextContainer ul li{
    line-height:33px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }
  .sec-3RowMaintextContainer ul svg{
    margin-right: 13px;
    margin-left: -40px;
  }
  .sec-3RowMaintextContainer ul p{
    line-height:33px;
  }
  .sec-3RowMaintextContainer ul{
    margin: 0px;
    padding: 0px 20px;
    list-style: none;
  }
  .sec-3Maincont{
    box-shadow: 1px 2px 3px 5px #eeeeee;
    padding: 50px 60px;
  }
  .sec-3Rowvideosec{
    text-align: center;
    margin-top: 40px;
  }
  .sec-3Rowvideosec h5{
    margin-top: 35px;
  }
  .sec-4{
    padding: 60px 60px;
  }
  .sec-4 label{
    font-weight: 600;
  }
  .sec-4contact-deat{
    padding-left: 70px;
  }
  .sec-4contact-deat h3{
    margin-bottom: 40px;
    margin-top: 20px;
    color: #ef7f1a;
    font-weight: 700;
  }
  .sec-4contact-deat p{
    margin: 40px 0px;
  }
  .sec-4contact-deat svg{
    font-size: 34px;
    margin-right: 15px;
  }
  
  .sec-4contact-form {
    -webkit-box-shadow: 10px 25px 80px rgb(150 150 150 / 15%);
    box-shadow: 10px 25px 80px rgb(150 150 150 / 15%);
    padding: 50px;
    /* margin-left: 50px; */
}
#msgErrorName,
#msgErrorEmail,
#msgErrorNumber,
#msgError,
.error
{
  font-size: 12px;
  color: red;
}
.submit-btns{
  background: #ef7f1a;
    border: none;
    border-radius: 24px;
    padding: 10px 0px;
    width: 30%;
    float: right;
}

.footer-sec{
  background-color: #115363;
  color: white;
  padding: 45px 65px;
} 
.footer-sec h4{
  margin-bottom: 30px;
}
.copyright p{
  text-align: center;
  margin-bottom: -30px;
  margin-top: 30px;
}
.copyright span a{
  color: #ef7f1a;
  text-decoration: none;
}
.footer-main-text a{
  color: #ef7f1a;
}
.footer-main-text a:hover{
  color: white;
}

.sec-3RowMaintextContainer ul{
  padding-left: 50px;  
}

.video-fluid {
  width: 100%;
  height: auto;
}

/* media querry for screens that are 992px or less */
@media screen and (max-width: 992px) {
  .booksBg{
    margin-bottom: 20px;
  }
  .sec-1Col-txt{
    text-align: center;
  }
  .sec-4contact-deat {
    padding-left: unset;
}

  
}
/* media querry for screens that are 576px or less */
@media screen and (max-width: 576px) {
  .sec-3Maincont {
    padding: 50px 10px;
  }
  .sec-3 {
    padding: 10px 15px;
}
.sec-4 {
  padding: 20px 15px;
}
.sec-4contact-form {
  padding: 20px;
}  
.footer-sec {
  padding: 45px 15px;
}
.sec-1 {
  padding: 20px 0px;
}
.sec-2 h3 {
  margin-bottom: 20px;
}
.sec-4contact-deat svg {
  margin-right: 1px;
}
}